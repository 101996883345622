import React, { useState } from "react"

import * as Icon from "react-feather"
import { Link } from "gatsby"
import { useScrollHandler } from "../hooks/scroll"

const TopMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [changeLang, setChangeLang] = useState(false);

    return (<>
        <section className={useScrollHandler() ? (menuOpen ? "top-menu scrolled" : "top-menu") : "top-menu scrolled shadow-sm"}>
            <div className="container">
                <div className="row">
                    <div className="col-6 col-lg-2 d-inline-flex align-items-center">
                        <Link to="/">
                            <img 
                                src="/img/logo.png" 
                                alt="" 
                                width="260px" 
                                className="img-fluid d-inline-flex logo" 
                            />
                        </Link>
                    </div>
                    <div className="col-6 col-lg-10 d-flex align-items-center justify-content-end" style={{ height: '100%' }}>
                        <div onClick={() => setMenuOpen(!menuOpen)} className="mobile-menu-button d-inline-block d-lg-none">
                            {menuOpen ? <Icon.X className="icon" /> : <Icon.Menu className="icon" />}
                        </div>
                        <ul className="list-inline d-none d-lg-inline-block m-0">
                            <li className="list-inline-item">
                                <Link to="/">Start</Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to="/solceller">Solceller</Link>
                            </li>
                            {/* <li className="list-inline-item">
                                <Link to="#0">Laddboxar</Link>
                            </li> */}
                            <li className="list-inline-item">
                                <Link to="/om-oss">Om oss</Link>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://konto.egenfaktura.com/login" className="btn btn-light">
                                    <Icon.PhoneCall size={16} />{' '}Ring oss på 070 964 03 00
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <div className={menuOpen ? "mobile-menu d-lg-none" : "mobile-menu d-none d-lg-none"}>
            <ul className="list-unstyled">
                <li><Link to="/">Start</Link></li>
                <li><Link to="/solceller">Solceller</Link></li>
                <li><Link to="/om-oss">Om oss</Link></li>
                <li>
                    <a href="https://konto.egenfaktura.com/login" className="btn btn-primary">
                        <Icon.PhoneCall size={16} />{' '}070 964 03 00
                    </a>
                </li>
                <li>
                    <a href="callto:46709640300" style={{ fontSize: '15px', color: '#2842ea' }}>070 964 03 00</a>
                    <span style={{ color: '#aaa', padding: '0px 5px', display: 'inline-block' }}>/</span>
                    <a href="mailto:kundtjanst@dinasolceller.se" style={{ fontSize: '15px', color: '#2842ea' }}>kundtjanst@dinasolceller.se</a>
                </li>
            </ul>
        </div>
    </>)
}

export default TopMenu
