import * as React from "react"

import TopMenu from "../components/topmenu"
import Portfolio from "../components/portfolio"
import Footer from "../components/footer"
import "./layout.scss"

const Layout = ({ children }) => {
  return (
    <>
      <TopMenu />
        {children}
        <Portfolio />
      <Footer />
    </>
  )
}

export default Layout
