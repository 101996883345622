import React from 'react';

const Footer = () => {
    return (
        <section className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-6 col-lg-3">
                        <strong>Information</strong>
                        <ul className="list-unstyled">
                            <li>
                                <a href="#0">Information om cookies</a>
                            </li>
                            <li>
                                <a href="#0">Integritetspolicy</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-3">
                        <strong>Kontakt</strong>
                        <ul className="list-unstyled">
                            <li>
                                <a href="mailto:info@dinasolceller.se">info@dinasolceller.se</a>
                            </li>
                            <li>
                                <a href="callto:0812209320">08 122 093 20</a>
                            </li>
                            <li>
                                Öppettider:<br className="d-lg-none" /> 08.00 - 17.00
                            </li>
                            <li>
                                Chatten öppen: 15.00 - 20.00
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-3 mt-5 mt-lg-0">
                        <strong>Mina sidor</strong>
                        <ul className="list-unstyled">
                            <li>
                                <a href="#0">Räkna på pris</a>
                            </li>
                            <li>
                                <a href="#0">Boka kostnadsfritt hembesök</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-3 mt-5 mt-lg-0">
                        <strong>Sociala medier</strong>
                        <ul className="list-unstyled">
                            <li>
                                <a href="#0">Facebook</a>
                            </li>
                            <li>
                                <a href="#0">Twitter</a>
                            </li>
                            <li>
                                <a href="#0">LinkedIn</a>
                            </li>
                            <li>
                                <a href="#0">Instagram</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <span className="copyright">Copyright © Dinasolceller {new Date().getFullYear()}</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer
