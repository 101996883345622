import React from 'react';

export default function Portfolio() {
    const portfolioImages = ['1.jpeg', '2.jpeg', '3.jpeg'];

    return (
        <section className="portfolio">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <h2>Referensobjekt</h2>
                    </div>
                </div>

                <div className="row mt-5">
                    {portfolioImages.map((img, index) => 
                        <div className={index === 0 ? "col-12 col-lg-4" : "col-12 col-lg-4 mt-5 mt-lg-0"} key={index}>
                            <div className="img" style={{
                                backgroundImage: 'url("/img/portfolio/' + img + '")'
                            }} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
